<template>
  <div>
    <dashboard-page-title
      addBtnLabel="+ طلب سحب"
      :showAddBtn="true"
      :showExportation="true"
      @addEvent="$bvModal.show('withdraw-payment')"
      >طلبات السحب</dashboard-page-title
    >
    <b-card class="w-20 mb-1">
      <span class="mx-2 font-size-20 font-weight-bold">الرصيد الحالى :</span>
      <span class="font-size-20 font-weight-bold">{{ balance }}</span>
    </b-card>
    <main-table :fields="fields" list_url="doctors/withdrawals"></main-table>
    <b-modal
      id="withdraw-payment"
      class="main-modal"
      size="lg"
      centered
      hide-header
      hide-footer
    >
      <div
        class="d-flex justify-content-between align-content-center border-bottom modal-head pb-3"
      >
        <div class="d-flex align-items-center gap_2">
          <h3 class="title">سحب رصيد من المحفظة</h3>
        </div>
        <b-button
          @click="$bvModal.hide('withdraw-payment')"
          class="back-btn border-0 bg-transparent p-0 m-0 back"
          >رجوع</b-button
        >
      </div>
      <div class="modal-body-content">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="position-relative p-4"
            @submit.prevent="handleSubmit(withdrawRequest)"
          >
            <div class="position-relative mb-5">
              <input-form
                name="amount"
                label="المبلغ المطلوب سحبه"
                placeholder=""
                :validate="{ required: true, regex: /^[1-9][0-9]*$/ }"
                v-model="withdraw.amount"
              />
              <span class="sub-label">ريال </span>
            </div>
            <div class="input-wrapper mb-5">
              <label
                for="pin-code"
                class="pin-code-label text-capitalize d-block"
                >رمز المحفظة</label
              >

              <PincodeInput
                v-model="withdraw.PIN_code"
                placeholder="-"
                :length="5"
                dir="ltr"
              />
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                variant="primary"
                class="save-modal-btn"
                type="submit"
                v-if="!loadingWidthdrawButton"
                >طلب سحب</b-button
              >
              <b-button variant="primary" class="save-modal-btn" v-else>
                <spinner-loading text="يتم تنفيذ الطلب" />
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>
<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
import doctorApi from "../../../doctorDashboard/settings/services/settings";
import PincodeInput from "vue-pincode-input";

export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      userData: {},
      balance: "",
      fields: [
        { label: "رقم الطلب", key: "id", class: "text-right" },
        { label: "المبلغ", key: "amount", class: "text-right" },
        { label: "الحالة", key: "status", class: "text-right" },
        { label: "تاريخ الإنشاء", key: "created_at", class: "text-right" },
      ],
      withdraw: {
        amount: "",
        PIN_code: "",
      },
    };
  },
  methods: {
    getUserInfo() {
      doctorApi.getDoctorInfo().then((response) => {
        this.userData = response.data;
        this.balance = response.data.wallet?.balance;
      });
    },
    withdrawRequest() {
      this.loadingWidthdrawButton = true;
      doctorApi
        .requestWithdraw(this.withdraw)
        .then((res) => {
          this.$bvModal.hide("withdraw-payment");
          core.showSnackbar("success", res.data.message);
        })
        .catch((err) => {
          this.loadingWidthdrawButton = false;
          core.showSnackbar("error", res.data.message);
        })
        .finally(() => {
          this.loadingWidthdrawButton = false;
        });
    },
    addMerchant() {
      console.log("hello");
    },
  },
  components: { PincodeInput },
  created() {
    this.getUserInfo();
  },
};
</script>
<style>
.back-btn {
  color: #d39d45 !important;
  font-size: 25px !important;
  font-weight: 600 !important;
}
.title {
  font-size: 1.953em;
  color: #646464 !important;
  font-family: "Expo", sans-serif;
  font-weight: 400;
  margin: 0px;
  line-height: 1.5;
}
.vue-pincode-input-wrapper {
  height: 50px !important;
  background: transparent !important;
  border: 1px solid #d7dbda !important;
  font-size: 14px !important;
  color: var(--iq-secondary) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  display: flex !important;
}
.vue-pincode-input {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  min-width: 50px !important;
  max-width: none !important;
  font-size: 20px !important;
}
.sub-label {
  position: absolute;
  bottom: 8px;
  left: 20px;
  color: #939393;
  font-size: 16px;
  font-weight: 600;
}
.d-block {
  font-size: 20px !important;
  color: #646464 !important;
  margin-bottom: 15px !important;
}
</style>
